import React, { FC } from 'react'
import { Trans, t } from '@lingui/macro'

import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import CloseIcon from 'components/page/header/components/hamburgerMenuContent/topContainer/CloseIcon'

import styles from './GeoblockInfoBox.module.scss'

export enum GeoblockDirectionType {
  Exclude = 'exclude',
  Include = 'include'
}

interface Props {
  onClose: () => void
}
export const GeoblockInfoBox: FC<Props> = ({ onClose }) => {
  const stream = useSelector(streamSelector)
  const geoblockDirection = stream?.data?.mediaObject?.geo_block.direction
  const countries = stream?.data?.mediaObject?.geo_block.countries

  const getDirectionText = () => {
    if (geoblockDirection === GeoblockDirectionType.Exclude)
      return t`It is unavailable in the following countries:`
    else if (geoblockDirection === GeoblockDirectionType.Include) {
      return t`It is only accessible in the following countries:`
    }
  }
  return (
    <div className={styles.InfoContainer}>
      <div className={styles.TopContainer}>
        <div className={styles.Title}>
          <Trans>This stream has geoblocking on</Trans>
        </div>
        <IconButton id="close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.GeoblockList}>
        <div className={styles.GeoblockDirection}>{getDirectionText()}</div>
        <div className={styles.GeoblockedCountries}>
          {countries.length > 0
            ? countries.map((country: string) => (
                <div className={styles.CountryLabel} key={country}>
                  {country}
                </div>
              ))
            : t`No countries found`}
        </div>
      </div>
    </div>
  )
}
