import React, { FC } from 'react'
import styles from './LinkIconButton.module.scss'
import TextIconButton, { IconButtonProps, ButtonType } from '../TextIconButton'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { Link } from 'react-router-dom'

interface LinkButtonProps extends Omit<IconButtonProps, 'onClick'> {
  link?: string
  href?: string
  children: React.ReactNode
  rootDomain?: string
  linkOnClick?: () => void
}

export const LinkIconButton: FC<LinkButtonProps> = ({
  link,
  href,
  linkOnClick,
  rootDomain,
  ...props
}) => {
  const rootDomainHref = rootDomain ? `https://${rootDomain}${href}` : href
  const target = rootDomain ? LinkTarget.NewWindow : LinkTarget.CurrentWindow
  if (link && !rootDomain) {
    return (
      <Link to={link} className={styles.LinkIconButton}>
        <TextIconButton {...props} onClick={() => null} />
      </Link>
    )
  } else if (rootDomainHref) {
    return (
      <a
        className={styles.LinkIconButton}
        href={rootDomainHref}
        onClick={linkOnClick}
        target={target}
      >
        <TextIconButton {...props} onClick={() => null} />
      </a>
    )
  }
  return null
}

export { ButtonType }
