import { t } from '@lingui/macro'
import { FilterType } from '../filterTypes'
import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { FilterButton } from './FilterSection'

export function getFilterButtons(
  filterClicked: (selected: string) => void,
  hasTeams: boolean,
  channelType?: ChannelTypes,
  isRootChannel?: boolean
) {
  const filterButtonList: FilterButton[] = [
    { text: t`Channels`, id: FilterType.Channels, filterClicked },
    {
      text: t`Upcoming streams`,
      id: FilterType.UpcomingStreams,
      filterClicked
    },
    { text: t`Replays`, id: FilterType.PastStreams, filterClicked },
    { text: t`Videos`, id: FilterType.Videos, filterClicked }
  ]
  const defaultList = [
    FilterType.Channels,
    FilterType.UpcomingStreams,
    FilterType.PastStreams,
    FilterType.Videos
  ]
  let filters: FilterType[]
  switch (channelType) {
    case ChannelTypes.league:
      filters =
        isRootChannel && hasTeams
          ? [
              FilterType.UpcomingStreams,
              FilterType.PastStreams,
              FilterType.Videos,
              FilterType.Channels
            ]
          : [
              FilterType.UpcomingStreams,
              FilterType.PastStreams,
              FilterType.Videos
            ]
      break
    case ChannelTypes.district:
      filters = isRootChannel
        ? defaultList
        : [
            FilterType.UpcomingStreams,
            FilterType.PastStreams,
            FilterType.Videos
          ]
      break
    case ChannelTypes.event:
      filters = [
        FilterType.UpcomingStreams,
        FilterType.PastStreams,
        FilterType.Videos
      ]
      break
    case ChannelTypes.team:
      filters = [
        FilterType.UpcomingStreams,
        FilterType.PastStreams,
        FilterType.Videos
      ]
      break
    case ChannelTypes.club:
      if (isRootChannel) {
        filters = hasTeams
          ? [
              FilterType.UpcomingStreams,
              FilterType.PastStreams,
              FilterType.Videos,
              FilterType.Channels
            ]
          : [
              FilterType.Videos,
              FilterType.UpcomingStreams,
              FilterType.PastStreams
            ]
      } else {
        filters = [
          FilterType.UpcomingStreams,
          FilterType.PastStreams,
          FilterType.Videos
        ]
      }

      break

    default:
      filters = defaultList
  }

  const filterButtons: any = filters.map((filter) =>
    filterButtonList.find((button) => button.id === filter)
  )
  return filterButtons
}
