import { mapBroadcastThumbnailResponse } from 'api/channel/mapBroadcastThumnbailFromResponse'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import { SearchQuery } from '../search.interface'

interface VideoSearch extends SearchQuery {}

interface VideoSearchResult {}

interface VideoSearchResults {
  data: VideoSearchResult[]
  totalCount: number
}

export const getVideoSearch = async ({
  searchTerm,
  channelSlug,
  rootChannelSlug,
  isRootChannel,
  pageNumber = 1,
  pageSize = 15
}: VideoSearch): Promise<VideoSearchResults> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'search/videos',
      params: {
        query: searchTerm,
        page: pageNumber,
        per_page: pageSize,
        ...(rootChannelSlug &&
          isRootChannel && { root_channel: rootChannelSlug }),
        ...(channelSlug && !isRootChannel && { channel: channelSlug })
      }
    })
    const responseData = await resultResponse.data
    const searchResults: any = mapBroadcastThumbnailResponse(responseData)
    return {
      data: searchResults,
      // @ts-ignore
      totalCount: resultResponse.totalCount || 0
    }
  } catch (e: any) {
    console.log(e)
    // @ts-ignore
    throw new Error(e.message)
  }
}
