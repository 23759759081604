import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import styles from './ChannelInfoSection.module.scss'
import AdminPanel from '../AdminPanel/AdminPanel'
import { isUserChannelAdminSelector } from 'redux-store/user/user.selectors'
import useAdminAccess from 'hooks/AdminAccess'
import classNames from 'classnames'

export const ChannelInfoSection: FC = () => {
  const channel = useSelector(channelSelector)
  const channelLogo = channel.data.logo_image_url
  const channelName = channel.data.name
  const channelSlug = channel.data.subdomain

  const isChannelAdmin = useSelector(isUserChannelAdminSelector)
  const adminAccess = useAdminAccess()
  const isAdmin = adminAccess.isAdmin || isChannelAdmin

  return (
    <div
      className={classNames(styles.ChannelInfoSection, {
        [styles.IsAdmin]: isAdmin
      })}
      data-testid="channel-info-section"
    >
      <div className={styles.LeftContainer}>
        <img src={channelLogo} alt={'Channellogo'} />
        <h1 className={styles.ChannelName}>{channelName}</h1>
      </div>
      {isAdmin && (
        <div className={styles.AdminButtons}>
          <AdminPanel channelSlug={channelSlug} isInChannelInfoSection />
        </div>
      )}
    </div>
  )
}
