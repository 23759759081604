import { GeoblockedIcon } from 'assets/icons/GeoblockedIcon'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import React, { FC, useState } from 'react'
import { GeoblockInfoBox } from './GeoblockInfoBox'
import styles from './GeoblockedInfoButton.module.scss'

export const GeoblockedInfoButton: FC = () => {
  const [showInfoContainer, setShowInfoContainer] = useState(false)
  return (
    <div className={styles.GeoblockedInfoContainer}>
      <IconButton
        id={'geo-bloc-info-button'}
        onClick={() => setShowInfoContainer(!showInfoContainer)}
      >
        <GeoblockedIcon />
      </IconButton>
      {showInfoContainer && (
        <GeoblockInfoBox onClose={() => setShowInfoContainer(false)} />
      )}
    </div>
  )
}
