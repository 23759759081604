import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { FilterType } from './filterTypes'

export const getDefaultFilter = (
  channelType: ChannelTypes,
  hasTeams: boolean,
  isRootChannel?: boolean
) => {
  switch (channelType) {
    case ChannelTypes.league:
    case ChannelTypes.district:
    case ChannelTypes.event:
    case ChannelTypes.team:
      return FilterType.UpcomingStreams
    case ChannelTypes.club:
      if (isRootChannel) {
        return hasTeams ? FilterType.UpcomingStreams : FilterType.Videos
      } else {
        return FilterType.UpcomingStreams
      }
    default:
      return FilterType.Channels
  }
}
