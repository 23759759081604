/* eslint-disable @typescript-eslint/indent */
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { ChannelLink } from './ChannelListItem'

export const getChannelLinkMapper =
  (view: string, channelType?: string) =>
  (data: AssociationClubEventDistrict) => {
    const channelLink: ChannelLink = {
      name: data.playpage_title,
      slug: data.subdomain,
      logo: data.profile_image_url,
      adminEmail: data.admin_email
    }

    if (channelType === ChannelTypes.club) {
      channelLink.hasAdminEmail = data?.has_admin_email

      if (view === 'teams') {
        channelLink.description = data?.leagues
          ?.map(({ name }) => name)
          .join(' | ')
      }
    }

    return channelLink
  }
