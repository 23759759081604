export const getUserHasAccess = (
  hasChannelAccess: boolean,
  userCanView: boolean,
  userHasPaid: boolean,
  isViewingAsAdmin: boolean,
  streamHasNoneRestriction: boolean,
  streamHasLogingInRequired: boolean
) => {
  if (!hasChannelAccess) {
    return userCanView
  }
  if (isViewingAsAdmin || streamHasNoneRestriction) {
    return true
  }
  if (streamHasLogingInRequired) {
    return isViewingAsAdmin
  }
  return userHasPaid
}
