import React, { FC, useCallback, useMemo } from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'

import SettingsIcon from '../Images/SettingsIcon'
import EditIcon from '../Images/EditIcon'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import {
  LinkIconButton,
  ButtonType
} from 'components/buttons/LinkIconButton/LinkIconButton'

import { useDataApi } from '../../../../api/useDataApi'
import { getIsAnyGameScheduleContent } from './getIsAnyGameScheduleContent'
import { ChannelTypes } from '../../channelType'
import {
  triggerGTMForContentManagerButton,
  triggerGTMForSettingsButton
} from './gtmForAdminPanel'

import styles from './adminPanel.module.scss'
import classNames from 'classnames'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

interface Props {
  channelSlug: string
  isInChannelInfoSection?: boolean
}

const AdminPanel: FC<Props> = ({ channelSlug, isInChannelInfoSection }) => {
  const channel = useSelector(channelSelector)
  const channelName = channel.data?.name
  const channelType = channel.data?.type

  const {
    data: isAnyGameScheduleContentAvailable,
    isLoading,
    isError
  } = useDataApi({
    apiCall: useCallback(getIsAnyGameScheduleContent, []),
    parameters: useMemo(
      () => ({
        channelSlug,
        channelType
      }),
      [channelSlug, channelType]
    )
  })
  const hasActiveIntegration =
    !isLoading && !isError && isAnyGameScheduleContentAvailable
  const showContentManager = true
  const breakpoint = useCurrentBreakpoint()
  const openSettings = () => {
    triggerGTMForSettingsButton(channelName, channelSlug, channelType)
  }
  const getContentManagerHref = () => {
    if (hasActiveIntegration) {
      if ([ChannelTypes.team, ChannelTypes.league].includes(channelType))
        return `/${channelSlug}/contentmanager/competitions`
      if ([ChannelTypes.club].includes(channelType))
        return `/${channelSlug}/contentmanager/teams`
    }
    return `/${channelSlug}/contentmanager`
  }

  const openContentManager = () => {
    triggerGTMForContentManagerButton(channelName, channelSlug, channelType)
  }
  const showIconOnly = isInChannelInfoSection && breakpoint <= Breakpoint.sm
  return (
    <div
      className={classNames(styles.AdminPanelContainer, {
        [styles.IsInChannelInfoSection]: isInChannelInfoSection
      })}
      data-testid="admin-panel-container"
    >
      <LinkIconButton
        id="settings"
        label={showIconOnly ? '' : t`Settings`}
        type={ButtonType.Ghost}
        buttonSize={isInChannelInfoSection ? 'Small' : 'Large'}
        link={`/${channelSlug}/settings/channel`}
        linkOnClick={openSettings}
        externalStyle={styles.SettingsButton}
      >
        <SettingsIcon />
      </LinkIconButton>
      {showContentManager && (
        <LinkIconButton
          id="contentManager"
          label={showIconOnly ? '' : t`Content manager`}
          type={ButtonType.Ghost}
          buttonSize={isInChannelInfoSection ? 'Small' : 'Large'}
          link={getContentManagerHref()}
          linkOnClick={() => openContentManager()}
          externalStyle={styles.ContentManagerButton}
        >
          <EditIcon />
        </LinkIconButton>
      )}
    </div>
  )
}
export default AdminPanel
