import { VideoPlayerData } from '../viewerPage.interfaces'

export const getBroadcastPath = (
  pathname: string,
  subdomain: string,
  mediaPlayerData: VideoPlayerData
) => {
  if (pathname.includes('games/g/')) {
    const id = mediaPlayerData.mediaObject?.game?.id
    return `/${subdomain}/games/g/${id}`
  }
  return `/${subdomain}/watch/${mediaPlayerData.mediaObject.slug}`
}
