import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import Button, { ButtonType } from 'components/buttons/button/Button'
import styles from './ViewAsAdminButton.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import rolesActions from 'redux-store/roles/roles.actions'
import useAdminAccess from 'hooks/AdminAccess'
import { isViewingAsAdminSelector } from 'redux-store/roles/roles.selector'
import { Tooltip, TooltipTheme } from 'components/Tooltip/Tooltip'
interface Props {
  showToolTip: boolean
}
export const ViewAsAdminButton: FC<Props> = ({ showToolTip }) => {
  const dispatch = useDispatch()
  const adminAccess = useAdminAccess()
  const hideViewAsAdminTooltip = 'hideViewAsAdminTooltip'

  const showAdminButtons = adminAccess.hasChannelAccess
  const [showViewAsAdminToolTip, setShowViewAsAdminToolTip] = useState(false)

  const isViewingAsAdmin = useSelector(isViewingAsAdminSelector)
  const label = isViewingAsAdmin ? t`Show as user` : t`Show as admin`
  useEffect(() => {
    if (!localStorage.getItem(hideViewAsAdminTooltip) && showToolTip) {
      setShowViewAsAdminToolTip(true)
    }
  }, [showToolTip])

  const handleOnConfirmTooltipClick = () => {
    localStorage.setItem(hideViewAsAdminTooltip, 'true')
    setShowViewAsAdminToolTip(false)
  }

  const handleClick = () => {
    if (isViewingAsAdmin) {
      dispatch(rolesActions.setViewingAsUser())
    } else {
      dispatch(rolesActions.setViewingAsAdmin())
    }
  }
  if (showAdminButtons) {
    return (
      <div className={styles.Container}>
        <Button
          data-testid="admin-toggle-view-button"
          type={ButtonType.GhostInverted}
          customStyle={styles.AdminButton}
          buttonSize="Small"
          label={label}
          onClick={handleClick}
        />
        {showViewAsAdminToolTip && (
          <div className={styles.TooltipContainer}>
            <Tooltip
              title={t`Admin tip!`}
              text={t`To view the content as an admin press this button.`}
              position="Bottom"
              pointerPosition="Right"
              confirmButtonText={t`Got it`}
              onConfirmButtonClick={handleOnConfirmTooltipClick}
              theme={TooltipTheme.Light}
            />
          </div>
        )}
      </div>
    )
  }
  return null
}
