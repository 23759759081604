/* eslint-disable no-case-declarations */
/* eslint-disable no-undef */
import { AnyAction } from 'redux'
import { RootState } from 'redux-store/store'
import PERMISSIONS from './permissions.constants'
import { PermissionsState } from './permissions.interface'
import { AccessRestrictionType } from 'interfaces'

const initialState: PermissionsState = {
  hasChannelAccess: false,
  canView: false,
  canDownload: false,
  hasUserPaid: false,
  hasAdminAccess: false
}

function permissionsReducer(
  state: PermissionsState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case PERMISSIONS.ROLES_UPDATED:
      const { roles, mediaObject, streamUrl } = action

      const loginRequired =
        mediaObject?.access_restriction ===
          AccessRestrictionType.LoginRequired && !roles.isLoggedIn
      const isGeoBlocked = streamUrl?.geoBlock?.is_geoblock_protected
      const hasUserPaid = !!(
        streamUrl &&
        !streamUrl.requirePayment &&
        !loginRequired
      )

      const hasAdminAccess =
        roles.isAdmin || roles.isSuperadmin || roles.isTechAdmin

      const hasChannelAccess = roles.isChannelAdmin || hasAdminAccess

      const canView =
        roles.isSuperViewer ||
        hasChannelAccess ||
        (hasUserPaid && !isGeoBlocked)

      const hasDownloadAvailable =
        !!mediaObject?.game && !!mediaObject?.allow_download

      const canDownload =
        hasDownloadAvailable &&
        ((roles.isLoggedIn && hasUserPaid) ||
          roles.isDownloader ||
          roles.isLeagueAdmin ||
          hasChannelAccess)

      return {
        ...state,
        hasChannelAccess,
        canView,
        canDownload,
        hasUserPaid,
        hasAdminAccess
      }

    default:
      return state
  }
}

export default permissionsReducer

export const permissionsSelector = (state: RootState) => state.permissions
