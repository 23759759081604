import { t } from '@lingui/macro'
import { LinkType, MenuLink } from '../../../components/page/menuLink/MenuLink'

export interface ChannelMenuLink extends MenuLink {
  id: string
  name: string
  hidden: boolean
  showInBreadcrumb: boolean
}

export const links: ChannelMenuLink[] = [
  {
    id: '1',
    name: 'start',
    type: LinkType.Route,
    link: '/',
    translationText: t`Start`,
    hidden: false,
    showInBreadcrumb: false
  },
  {
    id: '2',
    name: 'videos',
    type: LinkType.Route,
    link: '/videos',
    translationText: t`Videos`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '3',
    name: 'games',
    type: LinkType.Route,
    link: '/games',
    translationText: t`Games`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '4',
    name: 'leagues',
    type: LinkType.Route,
    link: '/leagues',
    translationText: t`Leagues`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '6',
    name: 'events',
    type: LinkType.Route,
    link: '/events',
    translationText: t`Cups/Events`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '7',
    name: 'clubs',
    type: LinkType.Route,
    link: '/clubs',
    translationText: t`Clubs`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '8',
    name: 'districts',
    type: LinkType.Route,
    link: '/districts',
    translationText: t`Districts`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '9',
    name: 'teams',
    type: LinkType.Route,
    link: '/teams',
    translationText: t`Teams`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '14',
    name: 'stats',
    type: LinkType.Route,
    link: '/stats',
    translationText: t`Stats`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '10',
    name: 'news',
    type: LinkType.Route,
    link: '/news',
    translationText: t`News`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '11',
    type: LinkType.Route,
    link: '/groups',
    name: 'groups',
    translationText: t`Groups`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '12',
    name: 'buy access',
    type: LinkType.Route,
    link: '/access',
    translationText: t`Buy access`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '13',
    name: 'subscriptions',
    type: LinkType.Route,
    link: '/subscriptions',
    translationText: t`Subscriptions`,
    hidden: false,
    showInBreadcrumb: true
  },
  {
    id: '20',
    name: 'shop',
    type: LinkType.Route,
    link: '/shop',
    translationText: t`Shop`,
    hidden: true,
    showInBreadcrumb: true
  },
  {
    id: '21',
    name: 'about',
    type: LinkType.Route,
    link: '/about',
    translationText: t`About us`,
    hidden: true,
    showInBreadcrumb: true
  },
  {
    id: '22',
    name: 'settings',
    type: LinkType.Route,
    link: '/settings',
    translationText: t`Settings`,
    hidden: true,
    showInBreadcrumb: true
  },
  {
    id: '21',
    name: 'search',
    type: LinkType.Route,
    link: '/search',
    translationText: t`Search`,
    hidden: true,
    showInBreadcrumb: false
  }
]
