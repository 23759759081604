import React, { FC } from 'react'
import classNames from 'classnames'

import ChannelName from './ChannelName/ChannelName'
import HeroImage from './HeroImage'
import LogoImage from './LogoImage'
import { FollowButton } from './FollowButton'
import Submenu from './Submenu/Submenu'

import ShowAsUserButton from './ShowAsUserButton'
import AdminPanel from './AdminPanel/AdminPanel'
import { ChannelPageTitle } from './ChannelPageTitle/ChannelPageTitle'
import { ChannelTypes } from '../channelType'
import { useLocation } from 'react-router-dom'
import { ChannelMenuLink } from './menuLinks'
import { ChannelInfoSection } from './ChannelInfoSection/ChannelInfoSection'
import styles from './Header.module.scss'

interface Props {
  isRootPage: boolean
  heroImageUrl: string | undefined
  logoImageUrl: string | undefined
  isAdmin: boolean
  channelSlug: string
  channelName: string
  channelId: string
  followed: boolean
  isViewingAsUser: boolean
  onChangeView: () => void
  onFollowChannel: () => void
  onSelectSubmenu: (id: string) => void
  selectedSubmenu: string
  channelType: ChannelTypes
  channelSubmenuLinks: ChannelMenuLink[]
  customContent?: {
    header?: string
    footer?: string
  }
  rootDomain: string
  sport: string
}

export const Header: FC<Props> = ({
  isRootPage,
  heroImageUrl,
  logoImageUrl,
  isAdmin,
  isViewingAsUser,
  onChangeView,
  channelSlug,
  channelName,
  channelId,
  followed,
  onFollowChannel,
  onSelectSubmenu,
  selectedSubmenu,
  channelType,
  channelSubmenuLinks,
  customContent,
  rootDomain,
  sport
}) => {
  const location = useLocation()
  const urlpath = location.pathname
  const isNotStartPage = channelSubmenuLinks.some(
    (link) => link.link !== '/' && urlpath.includes(link.link.toString())
  )

  const showHeroImage = !isNotStartPage
  const showAdminPanel = showHeroImage && isAdmin
  const showChannelInfoSection = !showHeroImage && !isRootPage
  const showSubmenuTopBorder =
    ![ChannelTypes.club, ChannelTypes.team].includes(channelType) &&
    !showHeroImage &&
    isRootPage

  return (
    <header className={styles.HeaderContainer}>
      <ChannelPageTitle
        selectedSubmenu={selectedSubmenu}
        customContent={customContent}
        rootDomain={rootDomain}
        sport={sport}
        channelSlug={channelSlug}
      />
      {showHeroImage && (
        <div
          className={classNames({
            [styles.HeaderRootContentContainer]: isRootPage,
            [styles.HeaderContentContainer]: !isRootPage
          })}
        >
          <div
            className={styles.ImagesContainer}
            data-testid="images-container"
          >
            <HeroImage
              isRootPage={isRootPage}
              imageUrl={heroImageUrl}
              canChangeImage={isAdmin && !isViewingAsUser}
              channelSlug={channelSlug}
            />
            {showAdminPanel && (
              <div className={styles.ShowAsUserButton}>
                <ShowAsUserButton
                  id={channelId}
                  isViewingAsUser={isViewingAsUser}
                  onClick={onChangeView}
                />
              </div>
            )}
            {!isRootPage && (
              <div className={styles.HeaderLogoImage}>
                <LogoImage
                  imageUrl={logoImageUrl}
                  canChangeImage={isAdmin && !isViewingAsUser}
                  channelSlug={channelSlug}
                />
              </div>
            )}
            <div className={styles.FollowButtonContainer}>
              <FollowButton
                id={channelId}
                followed={followed}
                onClick={onFollowChannel}
                hasTheme={isRootPage}
              />
            </div>
          </div>
          <ChannelName
            isRootPage={isRootPage}
            channelName={channelName}
            channelType={channelType}
          />
        </div>
      )}
      {showChannelInfoSection && <ChannelInfoSection />}
      {showAdminPanel && !isViewingAsUser && (
        <div className={styles.HeaderAdminPanel}>
          <AdminPanel channelSlug={channelSlug} />
        </div>
      )}
      <div
        className={classNames(styles.SubmenuContainer, {
          [styles.WithTopBorder]: showSubmenuTopBorder
        })}
      >
        <Submenu
          channelType={channelType}
          channelSlug={channelSlug}
          onSelected={onSelectSubmenu}
          selected={selectedSubmenu}
          isRootPage={isRootPage}
          menuLinks={channelSubmenuLinks}
          isNotStartPage={isNotStartPage}
        />
      </div>
    </header>
  )
}
Header.defaultProps = {
  customContent: undefined
}
