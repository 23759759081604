import { useCallback, useEffect, useState } from 'react'
import { isChrome } from 'react-device-detect'

type ChromecastProps = {
  url: string | undefined
}
class Caster {
  available = false

  sender: any = undefined

  receiver: any = undefined

  player: any = undefined

  controller: any = undefined

  media: any = undefined

  streamType: any = undefined

  APP_ID: string = 'CF47DC9B'

  availabilityInterval: number = 0

  retryAvailability: boolean = false

  init() {
    this.retryAvailability = true
    this.checkAvilability()
  }

  checkAvilability() {
    let retry = false
    try {
      this.available = (window as any).chrome.cast.isAvailable
      if (this.available) {
        this.setup()
        this.retryAvailability = false
      } else {
        retry = true
      }
    } catch {
      retry = true
    }
    if (retry && this.retryAvailability) {
      if (this.availabilityInterval < 20000) {
        this.availabilityInterval += 1000
      }
      setTimeout(() => {
        this.checkAvilability()
      }, this.availabilityInterval)
    }
  }

  setup() {
    this.sender = (window as any).cast.framework
    this.receiver = (window as any).chrome.cast
    if (!this.player) {
      this.player = new this.sender.RemotePlayer()
    }
    if (!this.controller) {
      this.controller = new this.sender.RemotePlayerController(this.player)
      this.getContext().setOptions({
        receiverApplicationId: this.APP_ID,
        autoJoinPolicy: this.receiver.AutoJoinPolicy.TAB_AND_ORIGIN_SCOPED
      })
    }
    this.media = this.receiver.media
    this.streamType = this.media.StreamType.BUFFERED
  }

  getContext() {
    if (this.sender) {
      return this.sender.CastContext.getInstance()
    }
    return undefined
  }

  getCurrentSession() {
    return this.getContext().getCurrentSession()
  }

  endCurrentSession() {
    if (this.getContext()) {
      this.getContext().endCurrentSession()
    }
  }

  requestSession() {
    this.getContext().requestSession()
  }

  getCurrentMedia() {
    if (
      this.getCurrentSession() &&
      this.getCurrentSession().getMediaSession()
    ) {
      return this.getCurrentSession().getMediaSession().media
    }
    return undefined
  }
}

const w = window as any
const caster: Caster = w.caster || new Caster()
if (!w.caster) {
  w.caster = caster
}

var lastTime = 0

const useChromecast = (props: ChromecastProps) => {
  const { url } = props
  const [available, setAvailable] = useState(false)
  const [connected, setConnected] = useState(false)
  const [wasConnected, setWasConnected] = useState(false)
  const [wasPlaying, setWasPlaying] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [shouldPlay, setShouldPlay] = useState(false)
  const [canSeek, setCanSeek] = useState(false)
  const [mediaLoaded, setMediaLoaded] = useState(false)
  const [playerState, setPlayerState] = useState<string>()
  const [lastPlaybackTime, setLastPlaybackTime] = useState(0)
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    setShouldPlay(false)
  }, [available, url])

  useEffect(() => {
    if (connected) {
      setWasConnected(true)
    }
  }, [connected, playerState])

  useEffect(() => {}, [playing])

  useEffect(() => {
    if (isChrome) {
      if (caster) {
        caster?.init()
      }
      let timeout = 1000
      const check = () => {
        if (caster.available) {
          setAvailable(true)
        } else {
          setTimeout(() => {
            check()
            if (timeout < 20000) {
              timeout += 1000
            }
          }, timeout)
        }
      }
      check()
    }
  }, [])

  useEffect(() => {
    if (available && caster) {
      if (caster.getContext()) {
        caster
          .getContext()
          .addEventListener(
            caster.sender.CastContextEventType.SESSION_STATE_CHANGED,
            onSessionStateChanged
          )
      }
      if (caster.player?.isConnected) {
        setPlayerState(caster.player.playerState)
        if (
          caster.getCurrentMedia() &&
          caster.getCurrentMedia().contentId === url
        ) {
          setCanSeek(true)
          setMediaLoaded(true)
        }
        if (caster.player.playerState !== 'PLAYING') {
          setWasPlaying(false)
          setPlaying(false)
        }
        setConnected(true)
      }
      if (caster.controller) {
        caster.controller.addEventListener(
          caster.sender.RemotePlayerEventType.ANY_CHANGE,
          onPlayerEvent
        )
        caster.controller.addEventListener(
          caster.sender.RemotePlayerEventType.IS_CONNECTED_CHANGED,
          onConnectedChanged
        )
      }
    }
    return () => {
      if (caster) {
        if (caster.getContext()) {
          caster
            .getContext()
            .removeEventListener(
              caster.sender.CastContextEventType.SESSION_STATE_CHANGED,
              onSessionStateChanged
            )
        }
        if (caster.controller) {
          caster.controller.removeEventListener(
            caster.sender.RemotePlayerEventType.ANY_CHANGE,
            onPlayerEvent
          )
          caster.controller.removeEventListener(
            caster.sender.RemotePlayerEventType.IS_CONNECTED_CHANGED,
            onConnectedChanged
          )
        }
      }
    }
  }, [available])

  const getCurrentTime = useCallback(() => {
    if (caster.player) {
      if (caster.player.currentTime && caster.player.currentTime > 0) {
        lastTime = caster.player.currentTime
      }
      return caster.player.currentTime
    }
    return 0
  }, [available])

  const onPlayerEvent = (event: any) => {
    switch (event.field) {
      case 'playerState':
        switch (event.value) {
          case 'PLAYING':
            setPlayerState('PLAYING')
            setPlaying(true)
            setCanSeek(true)
            setWasPlaying(true)
            break
          case 'PAUSED':
            setPlayerState('PAUSED')
            setPlaying(false)
            setWasPlaying(false)
            break
          case 'BUFFERING':
            setPlayerState('BUFFERING')
            break
          case 'IDLE':
            setPlayerState('IDLE')
            setWasPlaying(false)
            setPlaying(false)
            break
          default:
            // #
            break
        }
        break
      case 'mediaInfo':
        // #
        break
      case 'isMediaLoaded':
        setMediaLoaded(event.value)
        break
      case 'canSeek':
        setCanSeek(event.value)
        break
      case 'duration':
        setDuration(event.value)
        break
      default:
        // nothing
        break
    }
  }

  const onConnectedChanged = useCallback(
    (event: any) => {
      if (event.field === 'isConnected') {
        if (event.value === true) {
          setLastPlaybackTime(0)
          setCanSeek(false)
          setMediaLoaded(false)
          setWasPlaying(false)
          setPlaying(false)
          setConnected(true)
          if (url && shouldPlay) {
            loadMedia(url)
          }
        } else {
          setLastPlaybackTime(lastTime)
          setConnected(false)
        }
      }
    },

    [url, shouldPlay]
  )

  const onSessionStateChanged = (event: any) => {
    switch (event.sessionState) {
      case caster.sender.SessionState.SESSION_STARTING:
        // #
        break
      case caster.sender.SessionState.SESSION_STARTED:
        // #
        break
      case caster.sender.SessionState.SESSION_RESUMED:
        // #
        break
      case caster.sender.SessionState.SESSION_ENDED:
        // #
        break
      case caster.sender.SessionState.SESSION_START_FAILED:
        // #
        break
      default:
        // #
        break
    }
  }

  const loadMedia = (src: string | undefined = undefined) => {
    const playSrc = src || url
    const mediaInfo = new caster.media.MediaInfo(playSrc, 'HLS')
    mediaInfo.metadata = new caster.media.GenericMediaMetadata()
    mediaInfo.customData = null
    mediaInfo.streamType = caster.streamType
    mediaInfo.textTrackStyle = new caster.media.TextTrackStyle()
    mediaInfo.duration = null

    const request = new caster.media.LoadRequest(mediaInfo)

    caster
      .getCurrentSession()
      .loadMedia(request)
      .then(
        () => {
          setMediaLoaded(true)
        },
        // eslint-disable-next-line
        (errorCode: any) => {
          stopCasting()
        }
      )
  }

  const stopCasting = () => {
    const castSession = caster.getCurrentSession()
    if (castSession) {
      castSession.endSession(true)
    }
  }

  const openContextMenu = () => {
    if (available) {
      caster.requestSession()
    }
  }

  const play = useCallback(() => {
    if (!mediaLoaded) {
      setShouldPlay(true)
      loadMedia()
    } else {
      // eslint-disable-next-line no-lonely-if
      if (playing) {
        // eslint-disable-next-line no-empty
      } else {
        caster.controller.playOrPause()
      }
    }
  }, [available, mediaLoaded, playing])

  const pause = useCallback(() => {
    caster.controller.playOrPause()
  }, [available])

  const seek = useCallback(
    (value: number) => {
      caster.player.currentTime = value
      caster.controller.seek()
    },

    [available]
  )

  const setVolume = (value: number) => {
    caster.controller.setVolumeLevel(value)
  }

  useEffect(() => {}, [duration])

  return {
    available: available,
    openContextMenu: openContextMenu,
    loadMedia: loadMedia,
    connected: connected,
    wasConnected: wasConnected,
    playing: playing,
    wasPlaying: wasPlaying,
    getCurrentTime: getCurrentTime,
    duration: duration,
    lastPlaybackTime: lastPlaybackTime,
    playerState: playerState,
    mediaLoaded: mediaLoaded,
    canSeek: canSeek,
    setShouldPlay: setShouldPlay,
    play: play,
    pause: pause,
    seek: seek,
    setVolume: setVolume
  }
}

export default useChromecast
