/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Trans, t } from '@lingui/macro'

import './index.scss'
import splash404 from './404.png'
import {
  LinkButton,
  ButtonType
} from '../../components/buttons/LinkButton/LinkButton'

const NotFoundPage = () => {
  return (
    <div
      className="NotFoundContainer"
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center'
      }}
    >
      <img src={splash404} alt="splash-404" className="NotFoundSplashImage" />
      <h2>
        <Trans>Sorry, we can't find the page you're looking for.</Trans>
      </h2>
      <p>
        <Trans>
          The page you are looking for could not be found, this could be because
          the link is broken or the channel does not exist. Please try again or
          go back to the start page.
        </Trans>
      </p>
      <LinkButton
        link="/"
        label={t`Go back to start page`}
        type={ButtonType.Primary}
        buttonSize="Medium"
      />
    </div>
  )
}

export default NotFoundPage
