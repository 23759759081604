import React, { FC, useEffect, useState } from 'react'

import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { MobileSearch } from './MobileSearch/MobileSearch'
import { ColorType, SearchField } from 'components/Searchfield/SearchField'
import { useHistory } from 'react-router-dom'
import styles from './Search.module.scss'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import useCompanyParam from 'hooks/CompanyParam'
import { getSearchFieldPlaceHolder } from './getSearchFieldPlaceholder'

interface Props {
  hasBackground: boolean
  backgroundIsTransparentAtStart: boolean
}
export const Search: FC<Props> = ({
  backgroundIsTransparentAtStart,
  hasBackground
}) => {
  const [searchFieldColor, setSearchFieldColor] = useState(ColorType.Light)
  const channel = useSelector(channelSelector)
  const company = useCompanyParam()
  const channelName = channel?.data?.name
  const isRootChannel = channel?.data?.isRootChannel

  const history = useHistory()
  const breakPoint = useCurrentBreakpoint()
  const isDesktop = breakPoint > Breakpoint.sm
  const onSearch = (result: string) => {
    if (company) {
      history.push(
        `/${[company, '/search'].filter(Boolean).join('')}?query=${result}`
      )
    } else {
      history.push(`/search/?query=${result}`)
    }
  }

  useEffect(() => {
    if (backgroundIsTransparentAtStart) {
      setSearchFieldColor(ColorType.Dark)
    }
    if (hasBackground) {
      setSearchFieldColor(ColorType.Light)
    }
  }, [hasBackground, backgroundIsTransparentAtStart])

  return isDesktop ? (
    <div className={styles.SearchBar}>
      <SearchField
        type={searchFieldColor}
        onSearch={(searchInput: string) => onSearch(searchInput)}
        showBackArrow={false}
        onBackArrowClick={() => null}
        placeholder={getSearchFieldPlaceHolder(
          isRootChannel,
          channelName,
          company
        )}
      />
    </div>
  ) : (
    <MobileSearch
      onSearch={(searchInput: string) => onSearch(searchInput)}
      placeholder={getSearchFieldPlaceHolder(
        isRootChannel,
        channelName,
        company
      )}
    />
  )
}
