import { t } from '@lingui/macro'

export const getSearchFieldPlaceHolder = (
  isRootChannel: boolean,
  channelName: string,
  company?: string
) => {
  if (isRootChannel) {
    return t`Search`
  }
  if (company && !isRootChannel) {
    return t`Search on ${channelName}`
  } else {
    return t`Search within Solidsport`
  }
}
