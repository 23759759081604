import React, { FC, useState } from 'react'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import { BackArrowIcon } from 'assets/icons/BackArrowIcon'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { StreamOptionsButton } from './StreamOptionsButton/StreamOptionsButton'

import styles from './TopActionsBar.module.scss'
import { ViewAsAdminButton } from './ViewAsAdminButton/ViewAsAdminButton'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import classNames from 'classnames'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import { locationSelector } from 'redux-store/location/location.selectors'
import { streamHasGeoblockSelector } from 'redux-store/stream/stream.selectors'
import { GeoblockedInfoButton } from './GeoblockedInfoButton/GeoblockedInfoButton'
import useAdminAccess from 'hooks/AdminAccess'
import { Tooltip, TooltipTheme } from 'components/Tooltip/Tooltip'
import { t } from '@lingui/macro'

export const TopActionsBar: FC = () => {
  const channel = useSelector(channelSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const isGeoBlocked = useSelector(streamHasGeoblockSelector)
  const hideMoreOptionsTooltip = 'hideMoreOptionsTooltip'

  const adminAccess = useAdminAccess()
  const [showMoreOptionsToolTip, setShowMoreOptionsToolTip] = useState(
    !localStorage.getItem(hideMoreOptionsTooltip)
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const channelSlug = channel.data.subdomain
  const locations = useSelector(locationSelector)
  const showAdminButtons = adminAccess.hasChannelAccess
  const handleOnBackClick = () => {
    if (isViewingStream) {
      dispatch(userInterface.closeViewStream())
    } else {
      if (locations?.pages?.length > 1) {
        history.goBack()
      } else {
        history.push(`/${channelSlug}`)
      }
    }
  }

  const handleOnConfirmTooltipClick = () => {
    localStorage.setItem(hideMoreOptionsTooltip, 'true')
    setShowMoreOptionsToolTip(false)
  }
  return (
    <div
      className={classNames(styles.TopActionsBar, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      <IconButton
        id={'back-arrow-button'}
        customStyle={styles.BackButton}
        onClick={handleOnBackClick}
      >
        <BackArrowIcon />
      </IconButton>
      <div className={styles.RightContainer}>
        {!isViewingStream && (
          <ViewAsAdminButton showToolTip={!showMoreOptionsToolTip} />
        )}
        {isGeoBlocked && showAdminButtons && <GeoblockedInfoButton />}
        <div className={styles.StreamOptionsButton}>
          <StreamOptionsButton />
          {showMoreOptionsToolTip && (
            <div className={styles.OptionsTooltipWrapper}>
              <Tooltip
                title={t`Helpfull tip!`}
                text={t`Here you can find more options for the stream.`}
                position="Bottom"
                pointerPosition="Right"
                confirmButtonText={t`Got it`}
                onConfirmButtonClick={handleOnConfirmTooltipClick}
                theme={TooltipTheme.Light}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
