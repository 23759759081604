import React, { FC } from 'react'
import Button, { ButtonProps, ButtonType } from '../button/Button'
import styles from './LinkButton.module.scss'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { Link } from 'react-router-dom'

interface LinkButtonProps extends Omit<ButtonProps, 'onClick'> {
  link?: string
  href?: string
  linkTarget?: LinkTarget
  rootDomain?: string
}

export const LinkButton: FC<LinkButtonProps> = ({
  link,
  href,
  linkTarget,
  rootDomain,
  ...props
}) => {
  const target = rootDomain
    ? LinkTarget.NewWindow
    : linkTarget && [LinkTarget.NewWindow].includes(linkTarget)
    ? LinkTarget.NewWindow
    : LinkTarget.CurrentWindow
  const resolvedHref = rootDomain ? `https://${rootDomain}${href}` : href
  if (link && !rootDomain) {
    return (
      <Link to={link} className={styles.LinkButton}>
        <Button {...props} onClick={() => null} />
      </Link>
    )
  } else if (resolvedHref) {
    return (
      <a className={styles.LinkButton} href={resolvedHref} target={target}>
        <Button {...props} onClick={() => null} />
      </a>
    )
  }
  return null
}

export { ButtonType }
