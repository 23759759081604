import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import DATETIME from '../../../../constants/dateTime.constants'

export default function getDateText(startAt: number) {
  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
  dayjs.extend(isToday)
  dayjs.extend(isTomorrow)
  dayjs.extend(isYesterday)

  const todaysDate = dayjs(new Date()).unix()

  const getDateString = () => {
    if (startAt < todaysDate) {
      return dayjs.unix(startAt).format('D MMM HH:mm')
    }
    if (dayjs.unix(startAt).isToday()) {
      return `${t`Today`} ${dayjs.unix(startAt).format('HH:mm')}`
    }
    if (dayjs.unix(startAt).isTomorrow()) {
      return `${t`Tomorrow`} ${dayjs.unix(startAt).format('HH:mm')}`
    }

    if (startAt < todaysDate + DATETIME.SIX_DAYS) {
      return dayjs.unix(startAt).format('ddd HH:mm')
    }
    return dayjs.unix(startAt).format('D MMM HH:mm')
  }
  return { getDateString, todaysDate }
}
