export enum ViewType {
  LIVE = 'LIVE',
  REPLAY = 'REPLAY',
  UPCOMING = 'UPCOMING',
  HIGHLIGHT = 'HIGHLIGHT',
  BROADCAST_MESSAGE = 'BROADCAST_MESSAGE',
  VIDEO = 'VIDEO',
  NO_LIVESTREAM = 'NO_LIVESTREAM',
  NO_PAST_LIVESTREAM = 'NO_PAST_LIVESTREAM',
  HAS_BEEN_BROADCASTED = 'HAS_BEEN_BROADCASTED'
}
