import React, { FC } from 'react'
import styles from '../ThumbnailLabel.module.scss'
import getDateText from '../getDateText'

interface Props {
  startAt: number
}
export const DateLabel: FC<Props> = ({ startAt }) => {
  const { getDateString } = getDateText(startAt)

  return (
    <div className={styles.Default} data-testid="replay-thumbnail-label">
      {`${getDateString()}`}
    </div>
  )
}
