import { SearchResultChannelItem } from 'pages/SearchResultPage/SearchResultTypes/ChannelResult/ChannelResult'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import { SearchQuery } from '../search.interface'
import { mapChannelSearchResults } from './mapChannelSearchResults'

interface ChannelSearch extends SearchQuery {}

interface ChannelSearchResults {
  data: SearchResultChannelItem[]
  totalCount: number
}

export const getChannelSearch = async ({
  searchTerm,
  channelSlug,
  rootChannelSlug,
  isRootChannel,
  pageNumber = 1,
  pageSize = 15
}: ChannelSearch): Promise<ChannelSearchResults> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'search/channels',
      params: {
        query: searchTerm,
        page: pageNumber,
        per_page: pageSize,
        ...(rootChannelSlug &&
          isRootChannel && { root_channel: rootChannelSlug }),
        ...(channelSlug && !isRootChannel && { channel: channelSlug })
      }
    })

    const responseData = await resultResponse.data
    const searchResults: SearchResultChannelItem[] =
      mapChannelSearchResults(responseData)

    return {
      data: searchResults,
      totalCount: resultResponse.totalCount || 0
    }
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
