import React, { FC } from 'react'

export const GeoblockedIcon: FC = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.125 9.91675C21.125 15.4584 14 20.2084 14 20.2084C14 20.2084 6.875 15.4584 6.875 9.91675C6.875 8.02708 7.62567 6.21481 8.96186 4.87861C10.2981 3.54242 12.1103 2.79175 14 2.79175C15.8897 2.79175 17.7019 3.54242 19.0381 4.87861C20.3743 6.21481 21.125 8.02708 21.125 9.91675Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 12.2917C15.3117 12.2917 16.375 11.2284 16.375 9.91675C16.375 8.60507 15.3117 7.54175 14 7.54175C12.6883 7.54175 11.625 8.60507 11.625 9.91675C11.625 11.2284 12.6883 12.2917 14 12.2917Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 26L18 22"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 26L10 22"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
