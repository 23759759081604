/* eslint-disable react/jsx-no-bind */
import React, { FC } from 'react'

import { AddAdmin } from './components/AddAdmin'
import { ShowAdmin } from './components/ShowAdmin'

import styles from './AdminDetails.module.scss'

interface AdminDetailsProps {
  slug: string
  hasAdminEmail?: boolean | null
}

export const AdminDetails: FC<AdminDetailsProps> = ({
  slug,
  hasAdminEmail
}) => (
  <div className={styles.AdminViewContainer} data-testid="admin-container">
    {hasAdminEmail ? (
      <ShowAdmin channelSlug={slug} />
    ) : (
      <AddAdmin channelSlug={slug} />
    )}
  </div>
)
AdminDetails.defaultProps = {
  hasAdminEmail: null
}
