import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import ShieldIcon from '../../cards/GameScheduleCard/Team/ShieldIcon'
import styles from './channelItem.module.scss'
import { AdminDetails } from './AdminDetails/AdminDetails'
import TrophyIcon from './Icons/TrophyIcon'
import useAdminAccess from '../../../hooks/AdminAccess'

export enum ChannelLinkIcons {
  shield,
  trophy
}
export interface ChannelLink {
  name: string
  description?: string
  slug: string
  logo?: string
  adminEmail?: string | null
  hasAdminEmail?: boolean | null
}

interface Props {
  channelLink: ChannelLink
  showAdmin?: boolean
  iconType?: ChannelLinkIcons
}

export const ChannelListItem: FC<Props> = ({
  channelLink: { slug, name, description, logo, hasAdminEmail },

  iconType = ChannelLinkIcons.shield
}) => {
  const { isSuperadmin } = useAdminAccess()

  const getIconToShow = () => {
    switch (iconType) {
      case ChannelLinkIcons.shield:
        return <ShieldIcon />
      case ChannelLinkIcons.trophy:
        return <TrophyIcon />
      default:
        return null
    }
  }
  return (
    <div className={styles.ChannelItemContainer}>
      <Link to={`/${slug}`} className={styles.LinkContainer}>
        {logo && logo !== '' ? (
          <img src={logo} alt={name} data-testid="channel-list-image" />
        ) : (
          <div className={styles.NoLogo} data-testid="channel-list-icon">
            {getIconToShow()}
          </div>
        )}
        <div className={styles.ListView}>
          <h3 className={styles.Name}>{name}</h3>
          {!!description && (
            <div className={styles.ListViewDescription}>{description}</div>
          )}
        </div>
      </Link>
      {isSuperadmin && (
        <AdminDetails slug={slug} hasAdminEmail={hasAdminEmail} />
      )}
    </div>
  )
}

ChannelListItem.defaultProps = {
  showAdmin: false,
  iconType: ChannelLinkIcons.shield
}
