import React, { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { getEventsForAssociation } from '../../../api/channel/getEventsForAssociation'
import { getEventsForDistrict } from '../../../api/channel/getEventsForDistrict'
import { ChannelListItem } from '../../../components/lists/ChannelListItem/ChannelListItem'
import { AssociationClubEventDistrict } from '../../../interfaces/AssociationClubEventDistrict.interface'
import { ChannelTypes } from '../channelType'

import { ChannelPageList } from '../ChannelList/ChannelPageList'
import { useChannelPage } from '../useChannelPage'

interface EventsProps {
  channelSlug: string
  channelType: ChannelTypes
}
const Events: FC<EventsProps> = ({ channelSlug, channelType }) => {
  const PER_PAGE_COUNT = 30
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [eventsData, setEventsData] = useState<AssociationClubEventDistrict[]>(
    []
  )

  const getEvents =
    channelType === ChannelTypes.association
      ? getEventsForAssociation
      : getEventsForDistrict

  const channelPageProps = useChannelPage({
    channelSlug,
    perPageCount: PER_PAGE_COUNT,
    currentPage: page,
    channelDataApiCall: getEvents,
    onData: (data: any) => setEventsData((prev) => [...prev, ...data]),
    setTotalPageCount: (total: any) => setTotalPages(total / PER_PAGE_COUNT)
  })

  const increasePageNumber = () => {
    setPage((prevValue) => prevValue + 1)
  }

  const isEmpty = eventsData?.length === 0
  const emptyMessage = t`There are no events registered yet`

  return (
    <ChannelPageList
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      onLoadMoreClick={increasePageNumber}
      currentPage={page}
      totalPages={totalPages}
      {...channelPageProps}
    >
      {eventsData &&
        eventsData.length > 0 &&
        eventsData.map((event: AssociationClubEventDistrict) => (
          <ChannelListItem
            key={event.subdomain}
            channelLink={{
              name: event.playpage_title,
              slug: event.subdomain,
              logo: event.profile_image_url
            }}
          />
        ))}
    </ChannelPageList>
  )
}

export default Events
